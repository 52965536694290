@import url('https://fonts.cdnfonts.com/css/inter');
@import url('./common.scss');
@import url('./colors.scss');

body {
  background-color: var(--Black);
  color: var(--Gray);
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
}


.main {
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 180px;

    .block {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;

      &.header {
        padding: 0;
        background: linear-gradient(180deg, #020202 0%, rgba(2, 2, 2, 0.90) 50.45%, rgba(2, 2, 2, 0.00) 100%);
        height: 809px;
        gap: 24px;
        background-position: center;
        background-size: cover;
        min-width: 100%;
        width: auto;

        .buttonTransparent {
          display: flex;
          padding: 14px 20px;
          gap: 10px;
          border-radius: 32px;
          border: 2px solid var(--Dark-gray);
          background: var(--Dark-blue);
          color: var(--White);
          font-size: 14px;
          font-weight: 400;
          margin-top: 64px;
        }

        h1 {
          max-width: var(--textWidthDesktop);
          background: linear-gradient(92deg, #FFF 0%, rgba(255, 255, 255, 0.60) 100%);
          text-align: center;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        p {
          max-width: var(--textWidthDesktop);
          text-align: center;
        }

        >div {
          display: flex;
          gap: 32px;
          align-items: center;

          >div {
            display: flex;
            align-items: center;
            gap: 12px;
          }
        }

        > video {
          position: absolute;
          z-index: -1;
          min-width: 100%;
          height: 809px;
          object-fit: cover;
        }
      }

      &.developers {
        gap: 32px;
        align-items: initial;

        .carousel {
          display: flex;
          max-width: 100%;
          overflow: hidden;

          .developer {
            padding: 24px 56px;
            transition: margin-left 3s linear;

            img {
              width: 70px;
            }
          }

          &:last-child {
            transform: scale(-1, 1);
            z-index: -1;

            > div {
              transform: scale(-1, 1);
              z-index: -1;
            }
          }
        }

        h2 {
          color: var(--White);
          text-align: center;
        }

        p {
          text-align: center;
        }
      }

      &.service {
        max-width: 1240px;
        gap: 32px;
        margin: 0 auto;
        align-items: unset;

        .services {
          display: flex;
          gap: 64px 32px;
          flex-wrap: wrap;

          .item {
            max-width: calc(50% - 16px);
            gap: 24px;
            display: flex;
            flex-direction: column;

            >.lottie {
              position: relative;
              padding: 18px;
              background-color: var(--Black);
              border-radius: 24px;
              // z-index: -1;

              video {
                border-radius: 12px;
                max-width: 100%;
              }
              
              .poster {
                margin: 0;
                border-radius: 12px;
                width: 100%;
              }

              .background {
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(2, 2, 2, 0.00) 0%, rgba(2, 2, 2, 0.10) 50%, #020202 100%);
                pointer-events: none;
                top: 0;
                left: 0;
              }

              ::before {
                content: "";
                position: absolute;
                top: -2px;
                bottom: -2px;
                left: -2px;
                right: -2px;
                background: linear-gradient(0deg, transparent 30%, #222);
                border-radius: 24px;
                z-index: -1;
              }

            }

            img {
              width: 32px;
            }

            .title {
              color: var(--White);
              font-size: 20px;
              font-weight: 400;
            }

            .description {
              color: var(--Gray);
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;

              a {
                font-size: 14px;
              }
            }
          }
        }

        h2 {
          max-width: var(--textWidthDesktop);
        }

        p {
          max-width: var(--textWidthDesktop);
        }
      }

      &.catalog {
        gap: 32px;
        max-width: 800px;
        margin: 0 auto;

        h2 {
          text-align: center;
        }

        video {
          border-radius: 12px;
          max-width: 100%;
        }
      }

      &.functionality {
        gap: 32px;
        max-width: 1240px;
        margin: 0 auto;
        align-items: flex-start;

        ul {
          margin: 0;
          padding: 0;
          margin-left: 18px;
          max-width: calc(750px - 18px);

          li {
            color: var(--Gray);
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 26px;
          }
        }

        .blocks {
          display: flex;
          flex-wrap: wrap;
          gap: 64px;
          margin-top: 32px;
          position: relative;
          z-index: -1;

          .block {
            display: flex;
            flex-direction: column;
            width: calc(100% / 3 - 43px);
            align-items: flex-start;
            gap: 24px;
            border-radius: 12px;
            padding: 12px;
            position: relative;
            background-color: var(--Black);

            ::before {
              content: "";
              position: absolute;
              top: -2px;
              bottom: -2px;
              left: -2px;
              right: -2px;
              background: linear-gradient(35deg, transparent 30%, #0066FF77);
              border-radius: 12px;
              z-index: -1;
            }

            .title {
              color: var(--White);
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .description {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }
          }
        }

        h2,
        p {
          max-width: var(--textWidthDesktop);
        }
      }

      &.joinForm {
        display: flex;
        gap: 32px;

        .form {
          display: flex;
          flex-direction: column;
          gap: 24px;

          label {
            display: flex;
            gap: 12px;
            flex-direction: column;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;

            a {
              font-size: 14px;
              font-weight: 400;
              line-height: normal;
            }

            input[type=text],
            input[type=email],
            input[type=tel] {
              display: flex;
              width: 100%;
              padding: 16px 26px;
              align-items: center;
              gap: 10px;
              border-radius: 12px;
              border: 1px solid var(--Medium-gray);
              background: var(--Medium-blue);
              outline: none;
              color: var(--Gray, #A7AAB0);
              font-family: Inter, sans-serif;
              font-weight: 400;
              line-height: normal;
              width: 470px;
            }
          }

          .privacyPolicy {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 8px;

            input {
              border-radius: 6px;
              border: 1px solid var(--White);
              appearance: none;
              background-color: transparent;
              margin: 0;
              width: 18px;
              height: 18px;
              display: flex;
              justify-content: center;
              align-items: center;

              &:checked::after {
                background-image: url(../assets/svg/check.svg);
                content: " ";
                contain: content;
                background-position: center;
                background-repeat: no-repeat;
                width: 70%;
                height: 70%;
              }
            }
          }
        }

        h2, p {
          text-align: center;
        }
      }

      &.successForm {
        display: none;
        gap: 32px;

        h2, p {
          text-align: center;
        }
      }
    }


    h1 {
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 70px;
      margin: 0;
    }

    h2 {
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--White);
      margin: 0;
      scroll-margin-top: 70px;
    }

    a {
      color: var(--White);
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px;
      text-decoration: none;
    }

    p {
      margin: 0;
    }
  }
}

@media screen and (max-width: 1310px) {
  body {
    font-size: 16px;
    line-height: 24px;

    .main {
      .content {
        margin: 0 32px;
        gap: 164px;

        .block {
          &.header {
            height: 720px;
            margin-left: -32px;
            margin-right: -32px;
          }

          &.developers {
            .carousel {
              display: flex;

              .developer {
                padding: 12px 28px;

                img {
                  width: 40px;
                }
              }
            }
          }

          &.service {
            .services {
              .item {
                max-width: 100%;

                img,
                .title,
                .description {
                  margin: 0 32px;
                }
              }
            }
          }

          &.functionality {
            .blocks {
              gap: 32px;

              .block {
                width: calc(50% - 32px);
              }
            }

            ul {
              li {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }

        h1 {
          font-size: 38px;
          line-height: 50px;
        }

        h2 {
          font-size: 32px;
        }

        a {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
    line-height: 22px;

    .main {
      .content {
        margin: 0 16px;
        gap: 128px;

        .block {
          &.header {
            height: 528px;
            height: 720px;
            margin-left: -16px;
            margin-right: -16px;

            .buttonTransparent {
              margin-top: 32px;
              font-size: 12px;
            }

            > div >:last-child {
              display: none;
            }
          }

          &.developers {
            gap: 24px;
          }

          &.service {
            .services {
              .item {
                .lottie {
                  padding: 8px;
                }

                img,
                .title,
                .description {
                  margin: 0px;
                }
              }
            }
          }

          &.functionality {
            .blocks {
              .block {
                width: 100%;
              }
            }

            ul {
              li {
                font-size: 14px;
                line-height: 22px;
              }
            }
          }

          &.joinForm {
            display: flex;
            gap: 32px;
    
            .form {
              display: flex;
              flex-direction: column;
              gap: 24px;
    
              label {
                display: flex;
                gap: 12px;
                flex-direction: column;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
    
                input[type=text],
                input[type=email],
                input[type=tel] {
                  max-width: 470px;
                  width: auto;
                }
              }
            }
          }
        }

        h1 {
          font-size: 24px;
          line-height: 44px;
        }

        h2 {
          font-size: 24px;
        }

        a {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}